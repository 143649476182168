import React, { useState } from 'react';
import Header from '../../components/header';
import { Box, Button, Card, FormControl, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import '../pricing/pricing.scss';
import Vacancyimg from '../../assets/img/Vacancyimg.png'
import Footer from '../../components/footer';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Paper from '@mui/material/Paper';
import SubscriptionCard from '../../components/subscriptionCard';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pricing_img from '../../assets/img/pricing_img.png'

const Pricing = () => {
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [value, setValue] = useState(0);
    const subscriptions = useSelector((state) => state.subscriptions?.subscriptions?.data?.subscriptions);// Set default value to 10
    const { t } = useTranslation("Pricing");
    
    return (
       
       <Box>
            <Header />
            <Box>

                <Grid container className='banner-pricing'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-center'>
                        <img src={pricing_img} className='mb-4'></img>
                        <Typography variant='h3' className='fs-64 fw-600 mb-24 mx-auto text-white maxw'>{t('section1.pricing_title')} </Typography>
                        <Typography variant='h4' className='fs-20 fw-400 mx-auto text-white lh-15' sx={{ maxWidth: "690px" }}>{t('section1.pricing_subtext')}</Typography>
                    </Grid>
                </Grid>


                <Grid container className="mb-5 px-5">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="SubscriptionPlan pricing_SubscriptionPlan">
                            {subscriptions?.map((item, index) => {
                                return (
                                    <SubscriptionCard item={item} key={index}></SubscriptionCard>
                                )
                            })}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-center mx-auto mb-3'>
                        <Box>
                            <Box className="divider-pricing-text nolegend"><p className='px-3 m-0'>
                                <span className='text-black p-0 fs-18 fw-500'>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            value={value}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={0}>
                                                <em>View Full plan comparison</em>

                                            </MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>

                                    </FormControl>
                                </span></p>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

                <Grid container className="px-5 pb-5 mb-3">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableContainer component={Paper} sx={{ boxShadow: "none !important" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='fs-16 fw-600 border-0' sx={{ width: "30%" }}></TableCell>
                                        <TableCell className='fs-16 fw-600 border-0' align="center">
                                            <Box className='flex-column align-items-center'>BASIC
                                                <Link to='/'><Button className='primary-btn-outline fs-16 text-capitalize' sx={{ width: "150px" }}>Get Started</Button> </Link>
                                            </Box>
                                        </TableCell>
                                        <TableCell className='fs-16 fw-600 border-0' align="center">
                                            <Box className='flex-column align-items-center'>Premium
                                                <Button className='primary-btn fs-16 text-capitalize themebtn' sx={{ width: "150px", border: '1px solid transparent' }}>Choose Plan</Button>
                                            </Box>
                                        </TableCell>
                                        <TableCell className='fs-16 fw-600 border-0' align="center">
                                            <Box className='flex-column align-items-center'>ENTERPRISE
                                                <Button className='primary-btn-outline fs-16 text-capitalize ' sx={{ width: "150px" }}>Choose Plan</Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody mt={2}>

                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Box className='flex-column' sx={{ gap: "30px" }}>
                                                <Box>
                                                    <Typography variant='h5' className='fs-18 fw-500'>All Facilities </Typography>
                                                    <Typography variant='h5' className='fs-16 fw-400'>Edit, Export , Share</Typography>
                                                </Box>
                                                <Typography variant='h5' className='fs-18 fw-500'>Lorem ipsum dolor </Typography>
                                                <Typography variant='h5' className='fs-18 fw-500'>sed do eiusmod tempor incididunt</Typography>
                                                <Typography variant='h5' className='fs-18 fw-500'>Duis aute irure dolor in reprehenderit in </Typography>
                                                <Typography variant='h5' className='fs-18 fw-500'>Ut enim ad minim veniam</Typography>
                                                <Box>
                                                    <Typography variant='h5' className='fs-18 fw-500'>Ut enim ad minim veniam</Typography>
                                                    <Typography variant='h5' className='fs-16 fw-400'>Atmos available on select titles only</Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box className='pricing-table-box'>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'>Basic</Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className=' fs-20 text-center fw-600' sx={{ color: "#BEBEBE" }}><CloseIcon></CloseIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-18 text-center fw-600' sx={{ opacity: "0.5" }}>2</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum dolor user</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box className='pricing-table-box'>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'>Premium</Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className=' fs-20 text-center fw-600' sx={{ color: "#BEBEBE" }}><CloseIcon></CloseIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-18 text-center fw-600' sx={{ opacity: "0.5" }}>2</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum dolor user</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box className='pricing-table-box'>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'>ENTERPRISE</Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-20 text-center fw-600'><CheckIcon></CheckIcon></Typography>
                                                <Typography variant='h5' className=' fs-20 text-center fw-600' sx={{ color: "#BEBEBE" }}><CloseIcon></CloseIcon></Typography>
                                                <Typography variant='h5' className='text-orange fs-18 text-center fw-600' sx={{ opacity: "0.5" }}>2</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum dolor user</Typography>
                                                <Typography variant='h5' className=' fs-18 text-center fw-500'>Lorem ipsum</Typography>
                                            </Box>
                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Box className="my-5 intersted">
          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
              <Card className='GenerationJobVacancy' sx={{ padding: '36px 24px 45px 55px' }}>
                <Box className='gap-20 d-flex flex-column text-start m-auto'>
                  <Typography variant='h4' className='fs-22 fw-500'>Interested?</Typography>
                  <Typography variant='h4' className='fs-55 fw-600'>{t('section3.vacancy_title')}</Typography>
                  <Typography variant='h4' className='fs-16 fw-600'>{t('section3.vacancy_subtext')}</Typography>
                  <Box className='d-flex gap-10 readybtn'>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtngenerat' sx={{ px: "24px" }}>Try for Free</Button>
                    </Link>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtnciscl' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button>
                    </Link>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Vacancyimg} />
            </Grid>
          </Grid>
        </Box>
                {/* <Box className="mb-5">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                            <Card className='GenerationJobVacancy' sx={{ padding: '8rem 3rem 9rem 5rem !important' }}>
                                <Box className='gap-30 d-flex flex-column text-start m-auto'>
                                    <Box>
                                        <Typography variant='h4' className='fs-22 fw-500 mb-12'>Interested?</Typography>
                                        <Typography variant='h4' className='fs-55 fw-600 mb-12'>{t('section3.vacancy_title')}</Typography>
                                        <Typography variant='h4' className='fs-16 fw-600 lh-15'>{t('section3.vacancy_subtext')} </Typography>
                                    </Box>
                                    <Box className='d-flex gap-10 themebtn'>
                                        <Link to={'/chatbot-update'}><Button className='primary-btn-outline' sx={{ px: "24px" }}>Try for Free</Button></Link>
                                        <Link to={'/chatbot-update'}><Button className='primary-btn-outline' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button></Link>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Vacancyimg} />
                        </Grid>
                    </Grid>
                </Box> */}
            </Box>
            <Footer sx={{ mt: "70px" }} />
        </Box>
    )
}

export default Pricing
