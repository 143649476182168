import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card, Grid } from '@mui/material';
import iqunderline from '../../assets/img/iqunderline.png'
import EastIcon from '@mui/icons-material/East';
import Header from '../../components/header';
import Footer from '../../components/footer';
import postjob from '../../assets/img/postjob.png';
import InteractwithChatbot from '../../assets/img/Interact with Chatbot.svg'
import PreviewTemplate from '../../assets/img/Preview Template.svg'
import PreviewTemplate90 from '../../assets/img/Preview Template 90.svg'
import NeuromarketingInfusedContent from '../../assets/img/NeuromarketingInfusedContent.jfif'
import Vacancyimg from '../../assets/img/Vacancyimg.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Start from '../../components/start';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubscriptions } from '../../redux/action/subscriptionAction';
import SubscriptionCard from '../../components/subscriptionCard';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/mediaquery.scss';


const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptions = useSelector((state) => state.subscriptions?.subscriptions?.data?.subscriptions);
  const IsLoggedIn = localStorage?.getItem("accessToken");
  const [visible, setVisible] = React.useState(true);
  const { t } = useTranslation("Home");
  const stripe = useStripe();
  const search = useLocation().search;

  React.useEffect(() => {
    dispatch(getAllSubscriptions());
  }, [])


  //show success message for stripe payment
  React.useEffect(() => {
    const clientSecret = new URLSearchParams(search).get('payment_intent_client_secret');

    if (stripe && clientSecret) {
      // Retrieve the PaymentIntent status using the client secret
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

        // Check if payment succeeded
        if (paymentIntent.status === 'succeeded') {
          toast.success('Subscription purchase was successful. It will be visible in dashboard shortly.');
          navigate('/');
        } else if (paymentIntent.status === 'requires_payment_method') {
          toast.error('Payment failed. Please try again.');
        }
      });
    }
  }, [stripe, search]);

  return (
    <Box>
      <Header />
      <Start visible={visible} setVisible={setVisible} />
      <Box>
        <Box>
          <Grid container className='banner'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-center'>
              <img src={iqunderline} className='mb-4'></img>
              <Typography variant='h3' className='fs-43 fw-600 mb-3 mx-auto' sx={{ maxWidth: "1100px" }}>{t('section1.Precision_engineered')}</Typography>
              <Typography variant='h4' className='fs-18 fw-500 mx-auto' sx={{ maxWidth: "920px" }}>{t('section1.graphic_design')}</Typography>

              <Box className='d-flex gap-10 justify-content-center' sx={{ mt: "60px", mb: "10px" }}>
                <Link to={IsLoggedIn ? '/chatbot-update' : '/signin'} className='d-flex Generate-Vacancy-btn' style={{ gap: "2px", textDecoration: "none" }}>
                  <Button className='primary-btn fs-16' sx={{ px: "24px", borderRadius: "74px !important" }}>{t('section1.generate_vacancy')}</Button><Button className='primary-btn' sx={{ minWidth: '54px !important', height: '54px !important', borderRadius: "50% !important" }}><EastIcon /> </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="my-5 px-5  servicecard-container">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-center mx-auto mb-3'>
              <Box>
                <div className="divider-text mb-0"><p className='px-3 m-0'><span className='text-orange p-0 fs-27 fw-700'>{t('section2.Used_By')} &nbsp;</span><span className='p-0 fs-27 fw-700'>{t('section2.startups')}</span></p></div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box 
                className='d-flex gap-lg-4 gap-md-2 gap-y-3 gap-3 justify-content-center servicecard-container-flex'
                sx={{
                  flexFlow:{md:"row nowrap !important", xs:"row wrap !important"}
                }}
              >
                <Box className="service-card">
                  <Box className='m-auto'>
                    <Typography variant='h1' className='fs-25 fw-600'>{t('section2.numericalData1')}</Typography>
                    <Typography variant='h5' className='fs-16 fw-400' >{t('section2.services1')}</Typography>
                  </Box>
                </Box>
                <Box className="service-card">
                  <Box className='m-auto'>
                    <Typography variant='h1' className='fs-25 fw-600'>{t('section2.numericalData2')}</Typography>
                    <Typography variant='h5' className='fs-16 fw-400' >{t('section2.services2')}</Typography>
                  </Box>
                </Box>
                <Box className="service-card">
                  <Box className='m-auto'>
                    <Typography variant='h1' className='fs-25 fw-600'>{t('section2.numericalData3')}</Typography>
                    <Typography variant='h5' className='fs-16 fw-400' >{t('section2.services3')}</Typography>
                  </Box>
                </Box>
                <Box className="service-card">
                  <Box className='m-auto'>
                    <Typography variant='h1' className='fs-25 fw-600'>{t('section2.numericalData4')}</Typography>
                    <Typography variant='h5' className='fs-16 fw-400' >{t('section2.services4')}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="my-5  px-5">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='mx-auto'>
              <Box className='text-start titlr'>
                <Typography variant='h1' className='fs-48 fw-600'>{t('section3.post')} <span className='text-orange'>{t('section3.your_job')}</span></Typography>
                <Typography variant='h1' className='fs-48 fw-600'>{t('section3.ai')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant='h4' className='fs-18 fw-500 text-gray titlpara'>{t('section3.graphic_design')} </Typography>
                  <Box className="d-flex mt-5 gap-30">
                    <Box>
                      <img src={InteractwithChatbot} />
                    </Box>
                    <Box className='d-flex flex-column justify-content-around'>
                      <Typography variant='h3' className='fs-22 fw-600  '>{t('section3.interact_with_chatbot1')}</Typography>
                      <Typography variant='h3' className='fs-18 fw-500 gryclr' sx={{ color: '#1E293B' }}>{t('section3.publishing1')}</Typography>
                    </Box>
                  </Box>
                  <Box className="d-flex mt-5 gap-30">
                    <Box>
                      <img src={PreviewTemplate} />
                    </Box>
                    <Box className='d-flex flex-column justify-content-around'>
                      <Typography variant='h3' className='fs-22 fw-600 '>{t('section3.interact_with_chatbot2')}</Typography>
                      <Typography variant='h3' className='fs-18 fw-500 gryclr'  sx={{ color: '#1E293B' }}>{t('section3.publishing2')} </Typography>
                    </Box>
                  </Box>
                  <Box className="d-flex mt-5 gap-30">
                    <Box>
                      <img src={PreviewTemplate90} />
                    </Box>
                    <Box className='d-flex flex-column justify-content-around'>
                      <Typography variant='h3' className='fs-22 fw-600 '>{t('section3.interact_with_chatbot3')}</Typography>
                      <Typography variant='h3' className='fs-18 fw-500 gryclr' sx={{ color: '#1E293B' }}>{t('section3.publishing3')}</Typography>
                    </Box>
                  </Box>
                  <Box className='d-flex gap-10 ' sx={{ mt: "60px", mb: "10px" }}>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline textup fontw6 h54 w142 borrd33' sx={{ px: "24px" }}>Try for Free</Button></Link>
                    <Link to={'/chatbot-update'}> <Button className='primary-btn-outline h54 w54 borrd50' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button></Link>


                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='text-end'>
                  <Box className="postjob-card">
                    <img src={postjob}></img>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {
          <Box className="my-5 px-5 mt-100" >
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='text-center mx-auto mb-3'>
                <Box>
                  <Typography variant='h4' className='fs-22 fw-500' sx={{ color: "#2C2C2D" }}>  Plan</Typography>
                  <Box className="divider-text"><p className='px-3 m-0'><span className='p-0 fs-48 fw-600'>Our&nbsp;</span><span className='text-orange p-0 fs-48 fw-600'>Subscription Plan</span></p></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className="SubscriptionPlan">
                  {subscriptions?.map((item, index) => {
                    return (
                      <SubscriptionCard item={item} key={index}></SubscriptionCard>
                    )

                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
        <Box className="my-5 px-5 mt-72 readmoresection" sx={{maxWidth:"1600px", marginInline:"auto"}}>
          <Grid container>
            <Grid item xs={12}  className='text-start mx-auto mb-3'>
              <Box>
                <p className='px-3 m-0 d-flex flex-wrap mb-9'><span className='p-0 fs-48 fw-600'>{t('section5.tapping')}&nbsp;</span><span className='text-orange p-0 fs-48 fw-600'>{t('section5.intelligence')}&nbsp;</span><span className='p-0 fs-48 fw-600'>{t('section5.through_talent')}</span></p>
              </Box>
            </Grid>
            <Grid item xs={12} className='mb-3'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} >
                  <Card className='d-flex gap-20 article' sx={{ padding: '36px 24px 45px 55px', maxWidth:"100%", height:"100%" }}>
                    <Box sx={{ width: '264px', height: '243px' }} className='d-flex readmoreimg'>
                      <img className='m-auto' style={{ width: '264px', height: '100%', objectFit: 'cover' }} src={NeuromarketingInfusedContent} />
                    </Box>
                    <Box className='gap-20 d-flex flex-column text-start'>
                      <Typography className="fw-500 fs-22">{t('section5.Neuromarketing1')}</Typography>
                      <Typography className="fw-500 fs-18 fc">{t('section5.ai-chatbot1')}</Typography>
                      <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "article1" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section5.read-more')} <EastIcon></EastIcon></Link>
                    </Box>
                  </Card>
                </Grid>
                  <Grid item xs={12} sm={6} >
                  <Card className='d-flex gap-20 article' sx={{ padding: '36px 24px 45px 55px', maxWidth:"100%", height:"100%" }}>
                    <Box sx={{ width: '264px', height: '243px' }} className='d-flex readmoreimg'>
                      <img className='m-auto' style={{ width: '264px', height: '100%', objectFit: 'cover' }} src={NeuromarketingInfusedContent} />
                    </Box>
                    <Box className='gap-20 d-flex flex-column text-start'>
                      <Typography className="fw-600 fs-22">{t('section5.Neuromarketing2')}</Typography>
                      <Typography className="fw-500 fs-18 fc">{t('section5.ai-chatbot2')}</Typography>
                      <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "article2" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section5.read-more')}<EastIcon></EastIcon></Link>
                    </Box>
                  </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                  <Card className='d-flex gap-20 article' sx={{ padding: '36px 24px 45px 55px', maxWidth:"100%", height:"100%" }}>
                    <Box sx={{ width: '264px', height: '243px' }} className='d-flex readmoreimg'>
                      <img className='m-auto' style={{ width: '264px', height: '100%', objectFit: 'cover' }} src={NeuromarketingInfusedContent} />
                    </Box>
                    <Box className='gap-20 d-flex flex-column text-start'>
                      <Typography className="fw-600 fs-22">{t('section5.Neuromarketing3')}</Typography>
                      <Typography className="fw-500 fs-18 fc">{t('section5.ai-chatbot3')}</Typography>
                      <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "article3" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section5.read-more')} <EastIcon></EastIcon></Link>
                    </Box>
                  </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                  <Card className='d-flex gap-20 article' sx={{ padding: '36px 24px 45px 55px', maxWidth:"100%", height:"100%" }}>
                    <Box sx={{ width: '264px', height: '243px' }} className='d-flex readmoreimg'>
                      <img className='m-auto' style={{ width: '264px', height: '100%', objectFit: 'cover' }} src={NeuromarketingInfusedContent} />
                    </Box>
                    <Box className='gap-20 d-flex flex-column text-start'>
                      <Typography className="fw-600 fs-22">{t('section5.Neuromarketing4')}</Typography>
                      <Typography className="fw-500 fs-18 fc">{t('section5.ai-chatbot4')}</Typography>
                      <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "article4" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section5.read-more')} <EastIcon></EastIcon></Link>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="my-5 intersted">
          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
              <Card className='GenerationJobVacancy' sx={{ padding: '36px 24px 45px 55px' }}>
                <Box className='gap-20 d-flex flex-column text-start m-auto'>
                  <Typography variant='h4' className='fs-22 fw-500'>{t('section6.interested')}</Typography>
                  <Typography variant='h4' className='fs-55 fw-600'>{t('section6.generationg_job_vacancy')}</Typography>
                  <Typography variant='h4' className='fs-16 fw-600'>{t('section6.lorem')}</Typography>
                  <Box className='d-flex gap-10 readybtn'>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtngenerat' sx={{ px: "24px" }}>{t('section6.free')}</Button>
                    </Link>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtnciscl' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button>
                    </Link>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Vacancyimg} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default Home
