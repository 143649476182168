import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import downarrow from '../assets/img/downarrow.png';
import Menu from '@mui/material/Menu';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import company from '../assets/img/company.png';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from "react-redux";
import GridViewIcon from '@mui/icons-material/GridView';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import LogoutIcon from '@mui/icons-material/Logout';


export default function MenuDrawer() {
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userData = useSelector(state => state?.authenticationReducer);
  const navigate = useNavigate();
  const userToken = localStorage.getItem('accessToken');
  const [isLoggedIn, setIsLoggedIn] = React.useState(userToken ? true : false);
  const handleOpenUserMenu = (event) => {
    event.stopPropagation();
    setAnchorElUser(event.currentTarget);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenuClick = (action) => {
    handleCloseUserMenu();

    switch (action) {
      case 'logout':
        localStorage.clear();
        toast.dismiss();
        toast.success('Logged out successfully');

        setIsLoggedIn(false);
        navigate('/');
        break;
      case 'profile':
        navigate('/profile');
        break;
      case 'dashboard':
        navigate('/dashboard');
        break;
      default:
        break;
    }
  };

  const handleLogout=()=>{
    localStorage.clear();
    toast.dismiss();
    toast.success('Logged out successfully');

    setIsLoggedIn(false);
    navigate('/');
  }

  const settings = [
    { label: 'My Profile', action: 'profile' },
    { label: 'Dashboard', action: 'dashboard' },
    { label: 'Logout', action: 'logout' }
  ];
 
  const handleNavigation=(page)=>{
     switch(page){
     case 'Home':
      return navigate('/');
     
      case "Who We Are":
      return navigate('/ourwork') ;
     
      case 'Services':
        return navigate('/services');

      case 'Pricing':
      return navigate('/pricing');
      
      default:
        return navigate('/');

     }
  } 


  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      className="menubar_smallscreen"
    >
      <List>
        {["Home", "Who We Are", "Services", "Pricing"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>handleNavigation(text)}>
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List className="bottom_menu">

        {
          isLoggedIn &&
          <ListItem>
          <ListItemButton className="mb-0 p-0 profileaccord">
          <Button sx={{ minWidth: "fit-content" }} >
              <SentimentSatisfiedAltIcon/> Akshit Singh
            </Button>
          </ListItemButton>
        </ListItem>
        }
        
        {
          isLoggedIn &&  <ListItem>
          <ListItemButton className="mb-0 p-0 profileaccord">
          <Button sx={{ minWidth: "fit-content" }} >
          <CircleNotificationsIcon  />Notification
            </Button>
          </ListItemButton>
        </ListItem>
        }
       
        <ListItem>
          <ListItemButton className="mb-0 p-0 ">
            <Box className="profillist">
              <Typography onClick={isLoggedIn ?()=>navigate('/profile'):()=>navigate('/signup')} variant="p" className="mb-0 p-0"> {
                isLoggedIn? <><AccountCircleIcon/> My Profile</> : <><LoginIcon /> SignUp </> 
              } </Typography>

            
              
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton className="mb-0 p-0 ">
            <Box className="profillist">
              

              {isLoggedIn && <Typography onClick={()=>navigate('/dashboard')} variant="p" className="mb-0 p-0"><GridViewIcon/> Dashboard</Typography>}
              
            </Box>
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton className="mb-0 p-0">

            {
              isLoggedIn ?
                <>
                  <Box sx={{ flexGrow: 0 }} className="singupdiv">
                   
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem key={setting.label} onClick={() => handleUserMenuClick(setting.action)}>
                          <Typography textAlign="center">{setting.label}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>

                  </Box>

                </> : <Button className='signUp-btn my-auto' sx={{ minWidth: 'fit-content' }} onClick={() => navigate('/signup')}>Sign Up</Button>
            }
            {isLoggedIn &&
            <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className='singupbtn'>
                      <AccountCircleIcon />
                        <Typography className='fs-14 fw-600'>{userData?.fullName}</Typography>
                        <img src={downarrow} alt="Down Arrow" />
                      </IconButton>
                    </Tooltip>}
          </ListItemButton>
        </ListItem> */}
        <ListItem>
          <ListItemButton className="mb-0 p-0">
            <Button sx={{ minWidth: "fit-content" }} onClick={isLoggedIn ?handleLogout:()=>navigate('/signin')}>
              {
                isLoggedIn? <><LoginIcon /> Logout</> : <><LoginIcon /> Login </> 
              }
              
            </Button>
          </ListItemButton>
        </ListItem>

      </List>
    </Box>
  );

  

  return (
    <div className="mobilemenu">
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
