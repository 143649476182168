import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { personalInfoValidationSchema } from "../../utils/validation";
import { profile } from "../../redux/action/profileAction";
import { trimValue } from "../../utils/utilityFunctions/trimValue";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./profileStyle.css";


const PersonalInformation = () => {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state?.authenticationReducer
  );
  const profileInfo = useSelector(
    (state) => state?.profileReducer.personalInfoDetails
  );

  const initialValues = {
    fullName: profileInfo ? profileInfo?.fullName : "",
    email: profileInfo ? profileInfo?.email : "",
    alternativeEmail: profileInfo ? profileInfo?.alternativeEmail : "",
    phone: profileInfo ? profileInfo?.phone : "",
  };

  //handle form submission 
  const onSubmit = (values, { setSubmitting }) => {
    const trimmedValues = trimValue(values);
    setSubmitting(false);
    if (userData?._id) {
      dispatch(profile(userData?._id, trimmedValues));
    }
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 9.63px 35.53px -24.81px #757576",
        padding: "32px 75px 62px 27px",
        borderRadius: "0px 21.75px 21.75px 21.75px",
      }}
      className="tabinfo"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={personalInfoValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ }) => (
          <Form>
            <Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="color-primary fs-22 fw-600" variant="h3">
                  Personal Information
                </Typography>
                <Divider
                  sx={{
                    marginTop: "13px",
                    marginBottom: "35px",
                    border: "1px solid #B5B1B1",
                  }}
                />
                <Box sx={{ gap: "20px" }} className="flex-wrap">
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className="fs-14 fw-500 mb-10">Full Name</Typography>
                    <Field
                      as={TextField}
                      className="w-100 field-radious-30"
                      name="fullName"
                      placeholder="Enter full name"
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </Box>
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className="fs-14 fw-500 mb-10">
                      Primary Email
                    </Typography>
                    <Field
                      name="email"
                      as={TextField}
                      className="w-100 field-radious-30"
                      id="outlined-basic"
                      placeholder="Enter primary email"
                      variant="outlined"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </Box>
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className="fs-14 fw-500 mb-10">
                      Alternative Email
                    </Typography>
                    <Field
                      name="alternativeEmail"
                      as={TextField}
                      className="w-100 field-radious-30"
                      id="outlined-basic"
                      placeholder="Enter alternative email"
                      variant="outlined"
                    />
                    <ErrorMessage
                      name="alternativeEmail"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </Box>
                  <Box
                    sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}
                  >
                    <Typography className="fs-14 fw-500 mb-10">
                      Mobile Number
                    </Typography>
                    <Field name="phone" className="w-100 field-radious-30" id="outlined-basic">
                      {({ field, form }) => (
                        <PhoneInput
                          country="nl"
                          value={field.value || ""}
                          onChange={(value) =>
                            form.setFieldValue("phone", value)
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ marginTop: "74px" }}
              >
                <Box className="flex-wrap text-end justify-content-end ml-auto w-100 gap-10">
                  <Button
                    className="primary-btn"
                    sx={{ width: "144px" }}
                    type="reset"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-btn-outline"
                    sx={{ width: "144px" }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PersonalInformation;
