import { Tab, Tabs, styled  } from '@mui/material'

export const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-start',
});

export const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    padding: theme.spacing(1),
    borderRadius: '30px',
    fontStyle: "Montserrat",
    fontWeight: '600',
    flex: 1,
    '&.Mui-selected': {
        boxShadow: "-0.09px 3.63px 35.53px -17.81px #757576",
        borderRadius: "16px 16px 0px 0px !important",
        color: "#F89B53",
        maxWidth: "fit-content",
        padding: "10px 26px",
    },
    '&:not(.Mui-selected)': {
        color: '#7C7C7C',
        fontWeight: '500',
        maxWidth: "fit-content",
        padding: "10px 26px",

    },
}));