import React, { useEffect, useState } from "react";
// import { contentData } from "../utils/readMoreContent";
import { useLocation } from "react-router";
import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ReadMore = () => {
  const [currentSection, setCurrentSection] = useState("");
  const location = useLocation();
  const selectedLanguage=useSelector((state)=>state.language.selectedLanguage);
  const { articleNo } = location.state || {};
  const [getArticleData, setGetArticleData] = useState({});
  // const { i18n } = useTranslation();
  const {t}=useTranslation("ReadMore");
useEffect(()=>{
window.scrollTo(0,0);
},[])

useEffect(()=>{

},[selectedLanguage])

useEffect(() => {
    if (articleNo) {
      switch (articleNo) {
        case "article1":
          setGetArticleData(contentData.article1);
          setCurrentSection(Object.keys(contentData.article1)[0]); // Set default section
          break;
        case "article2":
          setGetArticleData(contentData.article2);
          setCurrentSection(Object.keys(contentData.article2)[0]); // Set default section
          break;
        case "article3":
          setGetArticleData(contentData.article3);
          setCurrentSection(Object.keys(contentData.article3)[0]); // Set default section
          break;
        case "article4":
          setGetArticleData(contentData.article4);
          setCurrentSection(Object.keys(contentData.article4)[0]); // Set default section
          break;

        case "our_work_article1":
          setGetArticleData(contentData.our_work_article1);
          setCurrentSection(Object.keys(contentData.our_work_article1)[0]); // Set default section
          break;
        case "our_work_article2":
          setGetArticleData(contentData.our_work_article2);
          setCurrentSection(Object.keys(contentData.our_work_article2)[0]); // Set default section
          break;
        case "our_work_article3":
          setGetArticleData(contentData.our_work_article3);
          setCurrentSection(Object.keys(contentData.our_work_article3)[0]); // Set default section
          break;
        case "our_work_article4":
          setGetArticleData(contentData.our_work_article4);
          setCurrentSection(Object.keys(contentData.our_work_article4)[0]); // Set default section
          break;
        default:
          setGetArticleData({});
      }
    }
  }, [articleNo,selectedLanguage]);

  const contentData = {
    article1: {
      [t('article1.title1')]:
        (
          <div>
            <h2 style={{ color: "#333", marginTop: "20px" }}>
            {t('article1.title1')}
            </h2>
            <p>
            {t('article1.content1a')} <b> {t('article1.content1b')}</b>. {t('article1.content1c')} <b>{t('article1.content1d')}</b> {t('article1.content1e')}
            </p>
          </div>
        ),
        [t('article1.title2')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title2')}
          </h2>
          <p>
          {t('article1.content2')}
          </p>
        </div>
      ),
      [t('article1.title3')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title3')}
          </h2>
          <p>
          {t('article1.content3a')} 
             <b>{t('article1.content3b')}</b> {t('article1.content3c')} <b>{t('article1.content3d')}</b>{t('article1.content3e')}
          </p>
        </div>
      ),
      [t('article1.title4')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title4')}
          </h2>
          <p>
          {t('article1.content4a')}
            <br />
            <b> {t('article1.content4b')}</b> <br />
            {t('article1.content4c')}
          </p>
        </div>
      ),
      [t('article1.title5')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title5')}
          </h2>
          <p>
            1. <b>{t('article1.content5a')}</b> {t('article1.content5b')}
            <br />
            ◦ {t('article1.content5c')}
            <br />
            2.<b>{t('article1.content5d')}</b> {t('article1.content5e')}
            <br />
            3. <b>{t('article1.content5f')}</b> {t('article1.content5g')}
          </p>
        </div>
      ),
      [t('article1.title6')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title6')}
          </h2>
          <p>
          {t('article1.content6a')}
            <br />
            <br />
            <b>{t('article1.content6b')}</b> <br />
            {t('article1.content6c')}
            <br />
            <br />
            {t('article1.content6d')}
            <br />
            <br />
            {t('article1.content6e')}
          </p>
        </div>
      ),
      [t('article1.title7')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title6')}
          </h2>
          <p>
            1. <b> {t('article1.content7a')}</b> {t('article1.content7b')}
            <br />
            2. <b>{t('article1.content7c')}</b> {t('article1.content7d')}
            <br />
            3. <b>{t('article1.content7e')}</b> {t('article1.content7f')}
          </p>
        </div>
      ),
      [t('article1.title8')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article1.title8')}
          </h2>
          <p>
          {t('article1.content8a')}
            <b>{t('article1.content8b')}</b> {t('article1.content8c')}
            <b>{t('article1.content8d')}</b>{t('article1.content8e')}
            <br />
            <br />
            {t('article1.content8f')}
          </p>
        </div>
      ),
    },
    article2: {
      [t('article2.title1')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title1')}
          </h2>
          <p>
          {t('article2.content1')}
          </p>
        </div>
      ),
      [t('article2.title2')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title2')}
          </h2>
          <p>
          {t('article2.content2')}
          </p>
        </div>
      ),
      [t('article2.title3')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title3')}
          </h2>
          <p>
          {t('article2.content3')}
          </p>
        </div>
      ),
      [t('article2.title4')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title4')}
          </h2>
          <p>
          {t('article2.content4')}
          </p>
        </div>
      ),
      [t('article2.title5')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title5')}
          </h2>
          <p>
          {t('article2.content5a')}
            <br />
            <br />
            {t('article2.content5b1')}
            <br />
            {t('article2.content5b2')}
            <br />
            {t('article2.content5b3')}
            <br />
            {t('article2.content5b4')}
            <br />
            {t('article2.content5b5')}
            <br />
            {t('article2.content5b6')}
            <br />
            {t('article2.content5b7')}
            <br />
            {t('article2.content5b8')}
            <br />
            {t('article2.content5b9')}
            <br />
            {t('article2.content5b10')}
            <br />
            {t('article2.content5b11')}
            <br />
            {t('article2.content5b12')}
            <br />
            {t('article2.content5b13')}
            <br />
            {t('article2.content5b14')}
            <br />
            {t('article2.content5b15')}
            <br />
            {t('article2.content5b16')}
            <br />
            {t('article2.content5b17')}
            <br />
            {t('article2.content5b18')}
            <br />
            {t('article2.content5b19')}
            <br />
            {t('article2.content5b20')}
            <br />
            <br />
            {t('article2.content5c')}
          </p>
        </div>
      ),
      [t('article2.title6')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title6')}
          </h2>
          <p>
          {t('article2.content6a')}
            <br />
            <br />
            {t('article2.content6b')}
            <br />
            <br />• <b>{t('article2.content6c')}</b> {t('article2.content6d')}
            <br />• <b>{t('article2.content6e')}</b> {t('article2.content6f')}
            <br />• <b>{t('article2.content6g')}</b> {t('article2.content6h')}
            <br />• <b>{t('article2.content6i')}</b> {t('article2.content6j')}
            <br />
            <br />
            {t('article2.content6k')}
          </p>
        </div>
      ),
      [t('article2.title7')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title7')}
          </h2>
          <p>
          {t('article2.content7a')}
            <br />
            <br />
            {t('article2.content7b')}
            <br />
            <br />
            {t('article2.content7c')} <b>{t('article2.content7d')}</b> {t('article2.content7e')}
            <br />
            {t('article2.content7f')}
          </p>
        </div>
      ),
      [t('article2.title8')]: (
        <div>
          <h2 style={{ color: "#333", marginTop: "20px" }}>
          {t('article2.title8')}
          </h2>
          <p>
          {t('article2.content8a')}
            <br />
            <br />
            1. <b> {t('article2.content8b')}</b>  {t('article2.content8c')}
            <br />
            2.  {t('article2.content8d')} <b> {t('article2.content8e')}</b>  {t('article2.content8f')}
            <br />
            3.  {t('article2.content8g')} <b> {t('article2.content8h')}</b>{" "}
            <br />
            4.  {t('article2.content8i')} <b>{t('article2.content8j')} </b> {t('article2.content8k')} 
            <br />
            <br />
            {t('article2.content8l')} 
          </p>
        </div>
      ),
    },
    article3: {
      [t('article3.title1')]:
      (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title1')}
      </h2>
      <p>
      {t('article3.content1')}
      </p>
      </div>
      ),
      [t('article3.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title2')}
      </h2>
      <p>
      {t('article3.content2a')} <b>{t('article3.content2b')}</b>{t('article3.content2c')}
      <b> {t('article3.content2d')}</b>{t('article3.content2e')} <b>{t('article3.content2f')}</b> {t('article3.content2g')}
      </p>
      </div>
      ),
      [t('article3.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title3')}
      </h2>
      <p>
      {t('article3.content3')}
      </p>
      </div>
      ),
      [t('article3.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title4')}
      </h2>
      <p>
      {t('article3.content4')}
      </p>
      </div>
      ),
      [t('article3.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title5')}
      </h2>
      <p>
      {t('article3.content5a')} <b>{t('article3.content5b')}</b> {t('article3.content5c')}{" "}
      <b>{t('article3.content5d')}</b> {t('article3.content5e')}
      </p>
      </div>
      ),
      [t('article3.title6')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      How to Become Twice as Attractive
      </h2>
      <p>
      {t('article3.content6a')}
      <br />
      <br />
      1. <b>{t('article3.content6b')}</b>
      <br />
      {t('article3.content6c')}
      <br />
      <br />
      {t('article3.content6d')}
      <br />
      <br />
      2. <b>{t('article3.content6e')}</b>
      <br />
      {t('article3.content6f')}
      <br />◦ <b>{t('article3.content6g')}</b> <br />
      {t('article3.content6h')}
      <br />◦ <b>{t('article3.content6i')}</b>
      <br />
      {t('article3.content6j')}
      <br />◦ <b>{t('article3.content6k')}</b>
      <br />
      {t('article3.content6l')}
      <br />◦ <b>{t('article3.content6m')}</b>
      <br />
      {t('article3.content6n')}
      </p>
      </div>
      ),
      [t('article3.title7')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article3.title7')}
      </h2>
      <p>
      {t('article3.content7a')}{" "}
      <b>{t('article3.content7b')}</b>{t('article3.content7c')}
      <br />
      <br />
      {t('article3.content7d')}
      <br />
      <br />
      {t('article3.content7e')}
      </p>
      </div>
      ),
      },
      article4: {
        [t('article4.title1')]:
      (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title1')}
      </h2>
      <p>
      {t('article4.content1a')}
      <br />
      <br />
      {t('article4.content1b')}
      </p>
      </div>
      ),
      [t('article4.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title2')}
      </h2>
      <p>
      {t('article4.content2a')}{" "}
      <b>{t('article4.content2b')}</b> {t('article4.content2c')}
      <br />
      <br />
      {t('article4.content2d')} <b>{t('article4.content2e')}</b> {t('article4.content2f')} <b>{t('article4.content2g')}</b>{t('article4.content2h')}
      <br />
      <br />
      {t('article4.content2i')}
      <br />
      {t('article4.content2j')}
      <br />
      {t('article4.content2k')}
      <br />
      <br />
      {t('article4.content2l')}
      <br />
      <br />
      {t('article4.content2m')}
      </p>
      </div>
      ),
      [t('article4.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title3')}
      </h2>
      <p>
      {t('article4.content3a')}
      <br />
      <br />
      {t('article4.content3b')} <b>{t('article4.content3c')}</b>
      <br />
      <br />{t('article4.content3d')}{" "}
      <b>{t('article4.content3e')}</b> {t('article4.content3f')}
      <br />
      <br />
      {t('article4.content3g')} <b>{t('article4.content3h')}</b>,
      {t('article4.content3i')}{" "}
      <b>{t('article4.content3j')}</b>.
      </p>
      </div>
      ),
      [t('article4.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title4')}
      </h2>
      <p>
      {t('article4.content4a')} <b>{t('article4.content4b')}</b>{t('article4.content4c')}
      <br />
      <br />
      {t('article4.content4d')}
      <br />
      {t('article4.content4e')}
      <br />
      <br />
      {t('article4.content4f')}
      <br />
      <br />
      {t('article4.content4g')}
      </p>
      </div>
      ),
      [t('article4.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title5')}
      </h2>
      <p>
      {t('article4.content5a')}
      <br />
      <br />
      • {t('article4.content5b')}
      <br />
      • {t('article4.content5c')}
      <br />• {t('article4.content5d')}
      </p>
      </div>
      ),
      [t('article4.title6')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('article4.title6')}
      </h2>
      <p>
      • {t('article4.content6a')}
      <br />
      • {t('article4.content6b')}
      <br />
      • {t('article4.content6c')}
      <br />
      • {t('article4.content6d')}
      <br />
      • {t('article4.content6e')} <br />
      • {t('article4.content6f')} <br />
      • {t('article4.content6g')} <br/>
      • {t('article4.content6h')}
      </p>
      </div>
      ),
      },
      our_work_article1: {
        [t('our_work_article1.title1')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title1')}
      </h2>
      <p>
      {t('our_work_article1.content1a')}{" "}
      <b>{t('our_work_article1.content1b')}</b>{t('our_work_article1.content1c')}
      </p>
      </div>
      ),
      [t('our_work_article1.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title2')}
      </h2>
      <p>
      {t('our_work_article1.content2')}
      </p>
      </div>
      ),
      [t('our_work_article1.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title3')}
      </h2>
      <p>
      {t('our_work_article1.content3a')} <b>{t('our_work_article1.content3b')}</b> {t('our_work_article1.content3c')}
      </p>
      </div>
      ),
      [t('our_work_article1.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title4')}
      </h2>
      <p>
      {t('our_work_article1.content4')}
      </p>
      </div>
      ),
      [t('our_work_article1.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title5')}
      </h2>
      <p>
      {t('our_work_article1.content5a')} <br/><br/>•<b> {t('our_work_article1.content5b')}</b> {t('our_work_article1.content5c')}<br/> • <b>{t('our_work_article1.content5d')}</b> {t('our_work_article1.content5e')}<br/> •
      <b> {t('our_work_article1.content5f')}</b> {t('our_work_article1.content5g')}
      </p>
      </div>
      ),
      [t('our_work_article1.title6')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title6')}
      </h2>
      <p>
      {t('our_work_article1.content6')}
      </p>
      </div>
      ),
      },
      our_work_article2: {
        [t('our_work_article2.title1')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article1.title1')}
      </h2>
      <p>
      {t('our_work_article2.content1')}
      </p>
      </div>
      ),
      [t('our_work_article2.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article2.title2')}
      </h2>
      <p>
      {t('our_work_article2.content2')}
      </p>
      </div>
      ),
      [t('our_work_article2.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article2.title3')}
      </h2>
      <p>
      {t('our_work_article2.content3')}
      </p>
      </div>
      ),
      [t('our_work_article2.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article2.title4')}
      </h2>
      <p>
      {t('our_work_article2.content4')}
      </p>
      </div>
      ),
      [t('our_work_article2.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article2.title5')}
      </h2>
      <p>
      {t('our_work_article2.content5')}
      </p>
      </div>
      ),
      },
      our_work_article3: {
        [t('our_work_article3.title1')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article3.title1')}
      </h2>
      <p>
      {t('our_work_article3.content1')}
      </p>
      </div>
      ),
      [t('our_work_article3.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article3.title2')}
      </h2>
      <p>
      {t('our_work_article3.content2a')}<br/><br/> {t('our_work_article3.content2b')}
      </p>
      </div>
      ),
      [t('our_work_article3.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article3.title3')}
      </h2>
      <p>
      {t('our_work_article3.content3a')}<br/><br/> • <b>{t('our_work_article3.content3b')}</b> {t('our_work_article3.content3c')}<br/> • <b>{t('our_work_article3.content3d')}</b> {t('our_work_article3.content3e')}<br/> • <b> {t('our_work_article3.content3f')}</b> {t('our_work_article3.content3g')}<br/><br/> {t('our_work_article3.content3h')}
      </p>
      </div>
      ),
      [t('our_work_article3.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article3.title4')}
      </h2>
      <p>
      {t('our_work_article3.content4a')}<br/><br/> {t('our_work_article3.content4b')}<br/><br/> {t('our_work_article3.content4c')}
      </p>
      </div>
      ),
      [t('our_work_article3.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article3.title5')}
      </h2>
      <p>
      {t('our_work_article3.content5')}
      </p>
      </div>
      ),
      },
      our_work_article4: {
        [t('our_work_article4.title1')]:
      (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article4.title1')}
      </h2>
      <p> 
      {t('our_work_article4.content1')}
      </p>
      </div>
      ),
      [t('our_work_article4.title2')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article4.title2')}
      </h2>
      <p>
      {t('our_work_article4.content2a')}<br/><br/> {t('our_work_article4.content2b')}
      </p>
      </div>
      ),
      [t('our_work_article4.title3')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article4.title3')}
      </h2>
      <p>
      {t('our_work_article4.content3a')}<br/><br/> {t('our_work_article4.content3b')}
      </p>
      </div>
      ),
      [t('our_work_article4.title4')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article4.title3')}
      </h2>
      <p>
      {t('our_work_article4.content4a')}<br/><br/> {t('our_work_article4.content4b')}
      </p>
      </div>
      ),
      [t('our_work_article4.title5')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>
      {t('our_work_article4.title5')}
      </h2>
      <p>
      {t('our_work_article4.content5')}
      </p>
      </div>
      ),
      [t('our_work_article4.title6')]: (
      <div>
      <h2 style={{ color: "#333", marginTop: "20px" }}>{t('our_work_article4.title6')}</h2>
      <p>
      {t('our_work_article4.content6')}
      </p>
      </div>
      ),
      }
  };
  

  return (
    <>
      <Header />
      <div  style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        marginBottom: "50px",
        minHeight: "100vh", // Ensure the main container takes the full height
        display: "flex",
        flexDirection: "column", // Stack the content vertically
      }}
      
       className="readmorepag">
        {/* Header Section */}
        <div className="readmorebanner" style={{ width:"100%", backgroundColor: "#ffeedd",  margin:"auto", display:"flex",alignItems:"center", justifyContent:"center"}}>
        <div  style={{ }}>
          <h1 style={{ textAlign: "center", color: "#000000" , fontWeight:"700" , fontSize:"58px", maxWidth:"866px"}}>
            Lorem Ipsum is simply dummy text
          </h1>
        </div>
        </div>

        {/* Main Content Section */}
        <div style={{ display: "flex", padding: "80px 60px", flex: "1" }} className="tabhriztal">
          {/* Table of Contents */}
          <div
            style={{
              flex: "1",
              paddingRight: "0px",
              borderRight: "0px solid #F6F6F6",

            }}
            
          >
            <h3 style={{ color: "#212121", fontSize:"19px", fontWeight:"300", textAlign:"right",marginBottom:"21px" , marginRight:"18px"}}>TABLE OF CONTENTS</h3>
            <ul style={{ listStyleType: "none", padding: "0" }}>
              {Object.keys(getArticleData)?.map((section) => (
                <li
                  key={section}
                  onClick={() => setCurrentSection(section)}
                  style={{
                    cursor: "pointer",
                    borderRight:currentSection === section ? "5px solid #F89B53" : "5px solid #F6F6F6",
                  }}
                >
                  <div className="leftText"
                   style={{
                    margin: "0px 0",
                    color: currentSection === section ? "#212121" : "#656565",
                    fontWeight: currentSection === section ? "500" : "500",
                    backgroundColor:currentSection === section ? "#FFF4EC" : "",
                    borderRadius:"5px",
                    minHeight:"72px",
                    padding:"13px 20px",
                    textAlign:"right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flexEnd",
                    marginRight:"16px"
                  
                  }}
                  >
                  {section} 
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Article Content */}
          <div className="pannel_content " style={{ flex: "3", paddingLeft: "57px" , color:"#656565", fontSize:"18px", fontWeight:"400", lineHeight:"1.8",    textAlign:"left" }}>
            {getArticleData[currentSection]}
          </div>
        </div>
      </div>
      <Footer  style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
        padding: "10px 0",
        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.2)",
      }}/>
    </>
  );
};

export default ReadMore;
