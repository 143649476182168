import { Box, Button, Grid, List, ListItem } from '@mui/material'
import React from 'react'
import logo from '../../src/assets/img/logo.svg';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router';
import '../assets/styles/footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();
   const { t } = useTranslation("Footer");

  return (
    <Box style={{backgroundColor:"#FFFCF9"}} className="footer">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='px-5 mt-68'>
          <img width={138} height={52} src={logo} alt='Logo' className="footerlogo" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='px-5'>
          <Grid container>
            <Grid item xs={12} sm={4} md={6} lg={6} xl={6}>
              <List>
                <ListItem className='fs-16 fw-500 gap-16 align-items-flex-start'>
                  <Button className='primary-btn-outline' sx={{ minWidth: 'fit-content', borderColor: "#ffffff !important" }}>
                    <PhoneIcon></PhoneIcon>
                  </Button>
                  {t('tax_no')}</ListItem>
                <ListItem className='fs-16 fw-500 gap-10'>
                  <Button className='primary-btn-outline' sx={{ minWidth: 'fit-content' }}>
                    <PhoneIcon></PhoneIcon>
                  </Button>{t('kvk_no')}</ListItem>
                <ListItem className='fs-16 fw-500 gap-10'>
                  <Button className='primary-btn-outline' sx={{ minWidth: 'fit-content' }}>
                    <HomeIcon></HomeIcon>
                  </Button>
                  {t('address1')} <br></br>{t('address2')} <br></br> {t('country')}</ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={3} xl={3}>
              <List>
                <ListItem className='fs-18 fw-700' onClick={()=>navigate('/ourwork')} sx={{cursor:"pointer"}}>{t('who_we_are')}</ListItem>
                <ListItem className='fs-16 fw-500' onClick={()=>navigate('/ourwork')} sx={{cursor:"pointer"}}>{(t('our_work'))}</ListItem>
                <ListItem className='fs-16 fw-500' onClick={() => navigate('/contactus')} sx={{ cursor: "pointer" }}>{t('contact_us')}</ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3} xl={3} className='socialContainer'>
            <List className='social'>
              <ListItem className='fs-18 fw-700'>{t('others')}</ListItem>
              <ListItem className='fs-16 fw-500' onClick={()=>navigate('/feedback')} sx={{cursor:"pointer"}}>{t('feedback')} </ListItem>
              <ListItem className='fs-16 fw-500'>
                <Box className='flex-wrap' sx={{gap: "6px"}}>    
                  <Button className='primary-btn-outline align-items-start' sx={{ minWidth: 'fit-content' }}>
                    <TwitterIcon/>
                  </Button>
                  <Link to='https://www.facebook.com/iq.hire/'>
                  <Button className='primary-btn-outline align-items-start' sx={{ minWidth: 'fit-content' }}>
                    <FacebookIcon/>
                  </Button>
                  </Link>
                  <Link to="https://www.linkedin.com/company/iq-hire/">
                  <Button className='primary-btn-outline align-items-start' sx={{ minWidth: 'fit-content' }}>
                    <LinkedInIcon/>
                  </Button>
                  </Link>
                  <Link to="https://www.youtube.com/@IQHire">
                  <Button className='primary-btn-outline align-items-start' sx={{ minWidth: 'fit-content' }}>
                    <YouTubeIcon/>
                  </Button>
                  </Link>
                 
                  <Link to="https://www.instagram.com/iq_hire">
                  <Button className='primary-btn-outline' sx={{ minWidth: 'fit-content' }}>
                    <InstagramIcon/>
                  </Button>

                  </Link>
                </Box>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <List className='d-flex flex-wrap mt-4 mb-4 copyritrw'>
              <ListItem className='fs-14 fw-600' sx={{ color: '#2C2C2D !important', opacity: "0.5", width: "fit-content" }}>{t('copyright')}</ListItem>
              <ListItem className='fs-14 fw-600' sx={{ color: '#2C2C2D !important', opacity: "0.5", width: "fit-content",cursor: "pointer" }} onClick={() => navigate('/terms/privacy')} >{t('privacy')}&nbsp;&nbsp;&nbsp;</ListItem>
              <ListItem className='fs-14 fw-600' sx={{ color: '#2C2C2D !important', opacity: "0.5", width: "fit-content",cursor: "pointer" }} onClick={() => navigate('/terms/service')} >{t('terms')}</ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box >
  )
}

export default Footer
