import { Box, Grid, Typography, List, ListItem } from '@mui/material'
import React, { useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useTranslation } from 'react-i18next';
import '../../assets/styles/termsstyle.scss';

const PrivacyTerm = () => {
  const { t }=useTranslation("PrivacyTerm");

  useEffect(()=>{
  window.scrollTo(0,0); 
  },[])
 
  return (
   
   <Box>
      <Header></Header>
      <Box className="mb-120 content_pag">
        <Grid container className='px-5'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h1' className='fs-35 fw-800 text-center mb-20 mt-54 mt-130 mb-54'>{t('privacyStatement.title')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15  mt-54'><b>{t('privacyStatement.effectiveDate')}</b> {t('privacyStatement.effectiveDateValue')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15 mb-20'><b>{t('privacyStatement.lastUpdated')}</b> {t('privacyStatement.lastUpdatedValue')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15  text-center mx-auto wd-80 spacing' >{t('privacyStatement.at')} <b style={{ fontWeight: "600" }}>{t('privacyStatement.iqHire')}</b> , {t('privacyStatement.introductionStart')} <b style={{ fontWeight: "600" }}>{t('privacyStatement.gdpr')}</b> {t('privacyStatement.introductionEnd')} </Typography>

            {/* Data We Collect */}

            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.dataWeCollect.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15 '>{t('privacyStatement.dataWeCollect.description')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }} className="fs-15 fw-400 lh-15 ">
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item3')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item4')}
                  </Typography>
                </ListItem>
              </List>
            </Box>

            {/* How We Use Your Data */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.howWeUseYourData.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.howWeUseYourData.description')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item3')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataWeCollect.items.item4')}
                  </Typography>
                </ListItem>
              </List>
            </Box>


            {/* Data Sharing */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.dataSharing.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.dataSharing.description')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataSharing.items.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.dataSharing.items.item2')}
                  </Typography>
                </ListItem>
              </List>
            </Box>


            {/* How We Use Your Data */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.yourRights.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.yourRights.description')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.yourRights.items.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.yourRights.items.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.yourRights.items.item3')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.yourRights.items.item4')}
                  </Typography>
                </ListItem>
              </List>
              <Typography> {t('privacyStatement.yourRights.contact')}</Typography>
            </Box>

            {/*Data Retention  */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.dataRetention.title')}</Typography>
              <Typography variant='h3'className='fs-15 fw-400 lh-15'>{t('privacyStatement.dataRetention.description')}</Typography>
            </Box>


            {/* Cookies and Tracking Technologies*/}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.cookiesAndTracking.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.cookiesAndTracking.description')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.cookiesAndTracking.items.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                  {t('privacyStatement.cookiesAndTracking.items.item2')}
                  </Typography>
                </ListItem>
              </List>
              <Typography>{t('privacyStatement.cookiesAndTracking.note')}</Typography>
            </Box>

            {/* Security Measures */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.securityMeasures.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.securityMeasures.description')}</Typography>
            </Box>

            {/* Changes to This Privacy Statement */}
            <Box className="mt-20 ">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('privacyStatement.changesToStatement.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('privacyStatement.changesToStatement.description')}</Typography>
            </Box>

            <Typography variant='h4' className='fs-15 fw-600  lh-15 mb-10'>{t('privacyStatement.contact')}
              <b style={{ fontWeight: 400 }}>{t('privacyStatement.contactEmail')}</b> </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer></Footer>

    </Box>
  )
}

export default PrivacyTerm
