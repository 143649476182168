import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useDispatch, useSelector } from "react-redux";
import "../job/job.scss";
import editsvg from "../../assets/img/svg/edit.svg";
import editSelected from "../../assets/img/svg/editSelected.svg";
import copysvg from "../../assets/img/svg/copy.svg";
import downloadsvg from "../../assets/img/svg/download.svg";
import sharesvg from "../../assets/img/svg/share.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JobTitle from "../../assets/img/svg/JobTitle.svg";
import ToneofVoice from "../../assets/img/svg/ToneofVoice.svg";
import Neuromarketing from "../../assets/img/svg/Neuromarketing.svg";
import ElementsofValue from "../../assets/img/svg/ElementsofValue.svg";
import addsvg from "../../assets/img/svg/add.svg";
import sparksvg from "../../assets/img/svg/spark.svg";
import Loader from "../../components/Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Otherpopup from "./otherpopup";
import {
  editedVacancyTextData,
  getBeautifiedData,
  getSocialMediaTemplate,
  toneOfVoice,
} from "../../redux/action/chatbotAction";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Utils from "../../utils/reduxUtils";
import CancellationPopup from "../../common/CancellationPopup";
import {
  handleCopyContent,
  handleShareEmail,
  handleToneOfVoice,
} from "./helper";
import TestPdfTemplate from "./TestPdfTemplate";
import html2pdf from 'html2pdf.js';

const ShareJob = () => {

  const [customTone, setCustomTone] = useState("");
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [editedVacancyText, setEditedVacancyText] = useState(null);
  const { chatbotDataLoading, vacancyText, vacancyId } = useSelector(
    (state) => state?.jobDescriptionFileUploadReducer
  );
  const dispatch = useDispatch();
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isEditFocus = useRef(null);
  const pdfRef = useRef();

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const copyStoryTellingRef = useRef(null);
  const copyAboutTheFunctionRefs = useRef({});
  const copyCriteriaContentRefs = useRef({});
  const copyEmploymentConditionsContentRefs = useRef({});

  const [isPdfGettingDownloaded, setIsPdfGettingDownloaded] = useState(false);
  const [templateData, setTemplateData] = useState([]);


  const [isCancellationPopupOpen, setIsCancellationPopupOpen] = useState(false);
  const vacancyTextData =
    vacancyText[0]?.vacancyText !== undefined &&
      vacancyText[0]?.vacancyText !== null
      ? vacancyText[0]?.vacancyText
      : vacancyText[0];

  const [aboutTheFunction, setAboutTheFunction] = useState({});
  const [criteria, setCriteria] = useState([]);
  const [employmentConditions, setEmploymentConditions] = useState([]);

  const [roleOverView, setRoleOverView] = useState({});
  const [companyOverView, setCompanyOverView] = useState({});
  const [fittingTraits, setFittingTraits] = useState([]);
  const [reasonToJoin, setReasonToJoin] = useState([]);
  const [applicationInstructions, setApplicationInstructions] = useState("");

  const [trendingTitles, setTrendingTitles] = useState([]);
  const [trendingTitlesTooltipValue, setTrendingTitlesTooltipValue] =
    useState("");
  const [elementOfValues, setElementOfValues] = useState([]);
  const [elementOfTooltipValues, setElementOfTooltipValues] = useState({});
  const [neuromarketingValues, setNeuroMarketingValues] = useState([]);
  const [neuromarketingTooltipValues, setNeuromarketingTooltipValues] =
    useState([]);
  const [storeEditedData, setStoreEditedData] = useState(
    editedVacancyText !== null ? editedVacancyText : vacancyTextData
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

useEffect(() => {
    if (vacancyTextData) {
      setRoleOverView(vacancyTextData?.Role_Details);
      setCompanyOverView(vacancyTextData?.Company_Overview);
      setFittingTraits(vacancyTextData?.fitting_traits);
      setReasonToJoin(vacancyTextData?.reasons_to_join);
      setApplicationInstructions(vacancyTextData?.application_instructions);

      setAboutTheFunction(vacancyTextData?.About_The_Function);
      setCriteria(vacancyTextData?.Criteria);
      setEmploymentConditions(vacancyTextData?.Employment_Condition);
      const Keys = Object.keys(vacancyTextData?.Trending_Titles);
      const trendingTitles = vacancyTextData?.Trending_Titles;
      const datas = trendingTitles.map((data) => Object.keys(data).join(","));
      setTrendingTitles(datas.join(", "));
      const trendingValues = trendingTitles
        ?.map((data) => Object.values(data))
        .flat()
        .join(" ");
      const sentencesArray = trendingValues.split(".");

      // Step 2: Remove empty entries and rejoin with <br /> for line breaks
      const formattedString = sentencesArray
        .filter((sentence) => sentence.trim() !== "") // Filter out any empty strings from the split
        .map((sentence) => sentence.trim()) // Trim each sentence
        .join(".<br />");
      setTrendingTitlesTooltipValue(formattedString);
      // Initializing an array to store the tooltip values
      const trendingTitlesTooltipValue = [];

      // Iterating over the keys to extract and append values
      Keys.forEach((ele) => {
        // Access the value associated with the current key and store it in an array
        trendingTitlesTooltipValue.push(vacancyTextData?.Trending_Titles[ele]);
      });

      const elements = vacancyTextData?.Elements_of_values || [];
      const keysArray = [];
      const valuesArray = [];

      elements.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          keysArray.push(key);
          valuesArray.push(value);
        });
      });
      setElementOfValues(keysArray.join(", "));
      setElementOfTooltipValues(valuesArray.join("<br />"));

      const neuromarketing = vacancyTextData?.neuromarketing_principles || [];
      const neuromarketingArray = [];
      const neuromarketingValuesArray = [];

      neuromarketing.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          neuromarketingArray.push(key);
          neuromarketingValuesArray.push(value);
        });
      });
      setNeuroMarketingValues(neuromarketingArray.join(", "));
      setNeuromarketingTooltipValues(neuromarketingValuesArray.join("<br />"));
    }
    setEditedVacancyText(vacancyTextData);
  }, [vacancyTextData]);

  //handleBeautifyTemplate
  const handleBeautifyTemplate = () => {
    const payload = {
      structured_output: JSON.stringify(storeEditedData),
    };

    dispatch(getBeautifiedData(payload));
  };

  //Focus Job Title Field when edit is enabled
  useEffect(() => {
    if (isEditEnabled && isEditFocus?.current) {
      isEditFocus?.current?.focus();
    }
  }, [isEditEnabled]);

  //Copy Job Title Content 
  const handleCopyJobTitleContents = () => {
    const jobTitle =
      editedVacancyText?.["Job_Title"] ?? vacancyTextData?.["Job_Title"];

    if (jobTitle) {
      navigator.clipboard.writeText(jobTitle).then(() => {
        toast.success("Job Title copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Job Title:");
      });
    } else {
      alert("No Job Title to copy!");
    }
  };

  //Copy StoryTelling Content

  const handleCopyStoryTellingContents = () => {
    const storyTelling =
      editedVacancyText?.["Storytelling"] ?? vacancyTextData?.["Storytelling"];

    if (storyTelling) {
      navigator.clipboard.writeText(storyTelling).then(() => {
        toast.success("StoryTelling copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy storyTelling");
      });
    } else {
      alert("No storyTelling to copy!");
    }
  };

  //Copy Who we are
  const handleWhoWeAreContent = () => {
    const companyOverview =
      editedVacancyText?.Company_Overview["Who_Are_We"] ?? vacancyTextData?.Company_Overview["Who_Are_We"];

    if (companyOverview) {
      navigator.clipboard.writeText(companyOverview).then(() => {
        toast.success("Company Overview copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Company Overview");
      });
    } else {
      alert("No Company Overview to copy!");
    }
  };

  //Copy RoleDetails
  const handleRoleDetails = (key) => {
    let companyOverview;
    switch (key) {
      case 'Role_Overview':
        companyOverview =
          editedVacancyText?.Role_Details["Role_Overview"] ?? vacancyTextData?.Role_Details["Role_Overview"];
        break;
      case 'Motivation_for_Role':
        companyOverview =
          editedVacancyText?.Role_Details["Motivation_for_Role"] ?? vacancyTextData?.Role_Details["Motivation_for_Role"];
        break;
      case 'What_activities_can_you_expect_during_a_working_day?':
        companyOverview =
          editedVacancyText?.Role_Details["What_activities_can_you_expect_during_a_working_day?"] ?? vacancyTextData?.Role_Details["What_activities_can_you_expect_during_a_working_day?"];
        break;


      default:
        break;
    }
    // const companyOverview =
    // editedVacancyText?.Company_Overview["Who_Are_We"] ?? vacancyTextData?.Company_Overview["Who_Are_We"];

    if (companyOverview) {
      navigator.clipboard.writeText(companyOverview).then(() => {
        toast.success("Role Details copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Role Details");
      });
    } else {
      alert("No Role Details to copy!");
    }
  }
  //Copy Job Requirements
  const handleJobRequirementsContents = () => {
    const resultantItem = criteria?.map((item) => {
      const [key] = Object.entries(item)[0];
      const result = criteria?.find(
        (item) => Object.keys(item)[0] === key
      )?.[key] || "";
      return `${key}: ${result}`;
    }).join("\n");

    if (resultantItem) {
      navigator.clipboard.writeText(resultantItem).then(() => {
        toast.success("Job requirements copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Job requirements");
      });
    } else {
      alert("No Job requirements to copy!");
    }
  };

  //Copy characteristics 

  const handleCharacteristicsContent = () => {
    const Characteristics =
      (editedVacancyText?.["fitting_traits"] ||
        vacancyTextData?.["fitting_traits"]
      )?.join("\n") || ""

    if (Characteristics) {
      navigator.clipboard.writeText(Characteristics).then(() => {
        toast.success("Characteristics copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Characteristics");
      });
    } else {
      alert("No Characteristics to copy!");
    }
  };

  //Copy Employment Condition
  const handleEmploymentConditionsContent = () => {
    const resultantItem = employmentConditions?.map((item) => {
      const [key] = Object.entries(item)[0];
      const result = employmentConditions?.find(
        (item) => Object.keys(item)[0] === key
      )?.[key] || "";
      return `${key}: ${result}`;
    }).join("\n");

    if (resultantItem) {
      navigator.clipboard.writeText(resultantItem).then(() => {
        toast.success("Employment Condition copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Employment Condition");
      });
    } else {
      alert("No Employment Condition to copy!");
    }
  };

  //Copy Few Reasons to work
  const handleReasonsToWorkContent = () => {
    const reasonToJoin =
      (editedVacancyText?.["reasons_to_join"] ||
        vacancyTextData?.["reasons_to_join"]
      )?.join("\n") || ""

    if (reasonToJoin) {
      navigator.clipboard.writeText(reasonToJoin).then(() => {
        toast.success("Reasons to join copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Reasons to join");
      });
    } else {
      alert("No Reasons to join content to copy!");
    }
  };

  //Copy application instructions
  const handleCopyApplicationInstructions = () => {
    const companyOverview =
      editedVacancyText?.["application_instructions"] ?? vacancyTextData?.["application_instructions"];

    if (companyOverview) {
      navigator.clipboard.writeText(companyOverview).then(() => {
        toast.success("Company_Overview copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Company_Overview");
      });
    } else {
      alert("No Company_Overview to copy!");
    }
  };
  return (
    <>
      <Header />
      <Box className="py-2 mt-10">
        <Grid container spacing={2} className="px-5">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
            <Typography variant="h2" className="fs-32 fw-600 px-4">
              Job Vacancy Text
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className=" py-2 flex-wrap justify-content-end"
          >
            <Box className="gap-10 flex-wrap">
              <Box className="flex-wrap justify-content-end">
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
            <Box className="vacancy-content" ref={contentRef1}>
              <Typography
                className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                variant="h3"
              >
                Job Title
                <span>
                  <img
                    className="my-auto"
                    width={27}
                    height={27}
                    src={copysvg}
                    onClick={handleCopyJobTitleContents}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Typography>
              <TextareaAutosize
                ref={isEditFocus}
                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                aria-label="minimum height"
                minRows={1}
                placeholder="Enter Job Title"
                style={{ width: "100% " }}
                value={vacancyTextData?.["Job_Title"]}
                disabled
              />
              <Typography
                className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                variant="h3"
              >
                Story Telling
                <span>
                  <img
                    className="my-auto"
                    width={27}
                    height={27}
                    src={copysvg}
                    onClick={handleCopyStoryTellingContents}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Typography>
              <TextareaAutosize
                ref={copyStoryTellingRef}
                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                aria-label="minimum height"
                minRows={1}
                placeholder="Enter Story Telling"
                style={{ width: "100% " }}
                disabled
                value={vacancyTextData?.["Storytelling"]}
              />

              {Object.entries(companyOverView).map(([key]) => (
                <div key={key}>
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    {key === "Who_Are_We" && "Who are we?"}
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleWhoWeAreContent}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    value={vacancyTextData?.Company_Overview?.[key]}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    disabled
                  />
                </div>
              ))}

              {Object.entries(roleOverView).map(([key]) => (
                <div key={key}>
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    {key === "Role_Overview"
                      ? "What does the position entail?"
                      : key === "Motivation_for_Role"
                        ? "Why are you going to do this?"
                        : key ===
                          "What_activities_can_you_expect_during_a_working_day?"
                          ? "What activities can you expect during a working day?"
                          : key}

                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={() => handleRoleDetails(key)}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    value={vacancyTextData?.Role_Details?.[key]}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    disabled
                  />
                </div>
              ))}

              <Typography
                className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                variant="h3"
              >
                The job requirements:
                <span>
                  <img
                    className="my-auto"
                    width={27}
                    height={27}
                    src={copysvg}
                    onClick={handleJobRequirementsContents}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Typography>
              {(criteria || []).map((criteriaItem) => {
                const [key, value] = Object.entries(criteriaItem)[0];
                return (
                  <div key={key}>
                    {
                      value !== "" &&
                      <List
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "20px",
                        }}
                      >
                        <ListItem
                          key={key}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          style={{
                            display: "list-item",
                            listStylePosition: "inside",
                            color: "black",
                          }}
                        >
                          <>
                            <span style={{ fontWeight: "bold" }}>
                              {key}
                            </span>
                            <span>: {value}</span>
                          </>

                        </ListItem>
                      </List>

                    }

                  </div>
                );
              })}

              <div>
                <Typography
                  className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                  variant="h3"
                >
                  These characteristics suit you:
                  <span>
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCharacteristicsContent}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </Typography>
                <List
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    color: "black !important",
                  }}
                >
                  {fittingTraits?.map((item, index) => (
                    <ListItem
                      key={index}
                      className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                      style={{
                        display: "list-item",
                        listStylePosition: "inside",
                        color: "black",
                      }}
                    >
                      {item}
                    </ListItem>
                  ))}
                </List>
              </div>


              <Typography
                className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                variant="h3"
              >
                These are your employment conditions:
                <span>
                  <img
                    className="my-auto"
                    width={27}
                    height={27}
                    src={copysvg}
                    onClick={handleEmploymentConditionsContent}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Typography>



              {(employmentConditions || [])?.map((employmentCriteria) => {
                const [key, value] = Object.entries(employmentCriteria)[0];
                return (
                  <div key={key}>

                    {
                      value !== "" &&
                      <List
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "20px",
                        }}
                      >
                        <ListItem
                          key={key}
                          className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                          style={{
                            display: "list-item",
                            listStylePosition: "inside",
                            color: "black",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {key}
                          </span>
                          <span>: {value}</span>
                        </ListItem>
                      </List>
                    }
                  </div>
                );
              })}

              <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Just a few reasons to want to work with us
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleReasonsToWorkContent}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <div>
                    { 
                      <List
                        style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      >
                        {reasonToJoin?.map((item, index) => (
                          <ListItem
                            key={index}
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            style={{
                              display: "list-item",
                              listStylePosition: "inside",
                              color: "black",
                            }}
                          >
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    }
                  </div>


              <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Do you recognize yourself in the profile? This is how you
                    can apply for this position
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleCopyApplicationInstructions}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    ref={copyStoryTellingRef}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Story Telling"
                    style={{ width: "100% " }}
                    disabled
                    value={vacancyTextData?.["application_instructions"]}
                  />
            </Box>

            <TestPdfTemplate ref={pdfRef} templateData={templateData} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <Box
                  sx={{
                    background: "#FFF3E9",
                    height: "100%",
                    padding: "28px 24px ",
                  }}
                  ref={contentRef2}
                >
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={JobTitle} />
                        &nbsp; Job Title
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: trendingTitlesTooltipValue,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {trendingTitles}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ElementsofValue} />
                        &nbsp; Elements of Value
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: elementOfTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {elementOfValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={Neuromarketing} />
                        &nbsp; Neuromarketing{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: neuromarketingTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {neuromarketingValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ToneofVoice} />
                        &nbsp; Tone of Voice{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={vacancyTextData?.Tone_of_voice}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {vacancyTextData?.Tone_of_voice}{" "}
                      </Typography>
                    </Box>
                  </Box>
                 
                </Box>
              </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ShareJob;


{/* <Box className="py-2 mt-10">
<Grid container spacing={2} className="px-5">
  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
    <Typography variant="h2" className="fs-32 fw-600 px-4">
      Job Vacancy Text
    </Typography>
  </Grid>
  <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
    <Box className="vacancy-content">
      <Typography
        className="fs-18 text-color-c1 fw-700 mt-20"
        variant="h3"
      >
        Job Title
      </Typography>
      <Typography
        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
        variant="h3"
      >
        {vacancyTextData?.["Job_Title"]}{" "}
      </Typography>
      <Typography
        className="fs-18 text-color-c1 fw-700 mt-20"
        variant="h3"
      >
        Story Telling
      </Typography>
      <Typography
        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
        variant="h3"
      >
        {vacancyTextData?.Storytelling}
      </Typography>
      {Object.keys(aboutTheFunction).map((maxKey) => (
        <div key={maxKey}>
          <Typography
            className="fs-18 text-color-c1 fw-700 mt-20"
            variant="h3"
          >
            {maxKey}
          </Typography>
          <List>
            {aboutTheFunction[maxKey].map((item, index) => (
              <ListItem
                key={index}
                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
              >
                {item}
              </ListItem>
            ))}
          </List>
        </div>
      ))}

      {Object.keys(criteria).map((criteriaKey) => (
        <div key={criteriaKey}>
          <Typography
            className="fs-18 text-color-c1 fw-700 mt-20"
            variant="h3"
          >
            {criteriaKey}
          </Typography>
          <List>
            {criteria[criteriaKey]?.map((item, index) => (
              <ListItem
                key={index}
                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
              >
                {item}
              </ListItem>
            ))}
          </List>
        </div>
      ))}

      {Object.keys(employmentConditions).map((employmentKey) => (
        <div key={employmentKey}>
          <Typography
            className="fs-18 text-color-c1 fw-700 mt-20"
            variant="h3"
          >
            {employmentKey}
          </Typography>
          <List>
            {employmentConditions[employmentKey]?.map((item, index) => (
              <ListItem
                key={index}
                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
              >
                {item}
              </ListItem>
            ))}
          </List>
        </div>
      ))}
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
    <Box
      sx={{
        background: "#FFF3E9",
        height: "100%",
        padding: "28px 24px ",
      }}
    >
      <Box>
        <Box className="d-flex justify-content-between">
          <Typography
            variant="h4"
            className="fs-18 text-color-c1 fw-600 text-start"
          >
            <img width={24} height={24} src={JobTitle} />
            &nbsp; Job Title
          </Typography>
          <Tooltip
            className="text-orange"
            title={trendingTitlesTooltipValue}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
        <Box
          className="bg-white mt-10 mb-10"
          sx={{ padding: "12px 14px" }}
        >
          <Typography className="fs-16 fw-500 color-primary">
            {trendingTitles}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box className="d-flex justify-content-between">
          <Typography
            variant="h4"
            className="fs-18 text-color-c1 fw-600 text-start"
          >
            <img width={24} height={24} src={ElementsofValue} />
            &nbsp; Elements of Value
          </Typography>
          <Tooltip
            className="text-orange"
            title={elementOfTooltipValues}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
        <Box
          className="bg-white mt-10 mb-10"
          sx={{ padding: "12px 14px" }}
        >
          <Typography className="fs-16 fw-500 color-primary">
            {elementOfValues}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box className="d-flex justify-content-between">
          <Typography
            variant="h4"
            className="fs-18 text-color-c1 fw-600 text-start"
          >
            <img width={24} height={24} src={Neuromarketing} />
            &nbsp; Neuromarketing{" "}
          </Typography>
          <Tooltip
            className="text-orange"
            title={neuromarketingTooltipValues}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
        <Box
          className="bg-white mt-10 mb-10"
          sx={{ padding: "12px 14px" }}
        >
          <Typography className="fs-16 fw-500 color-primary">
            {neuromarketingValues}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box className="d-flex justify-content-between">
          <Typography
            variant="h4"
            className="fs-18 text-color-c1 fw-600 text-start"
          >
            <img width={24} height={24} src={ToneofVoice} />
            &nbsp; Tone of Voice{" "}
          </Typography>
          <Tooltip
            className="text-orange"
            title={vacancyTextData?.Tone_of_voice}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Box>
        <Box
          className="bg-white mt-10 mb-10"
          sx={{ padding: "12px 14px" }}
        >
          <Typography className="fs-16 fw-500 color-primary">
            {vacancyTextData?.Tone_of_voice}{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>
</Box> */}