import { Tab, Tabs, styled  } from '@mui/material'

export const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-start',
});

export const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    padding: theme.spacing(1),
    borderRadius: '30px',
    fontStyle: "Montserrat",
    fontWeight: 'bold',
    flex: 1,
    '&.Mui-selected': {
        backgroundColor: '#F89B53',
        color: theme.palette.common.white,
    },
    '&:not(.Mui-selected)': {
        color: '#F89B53',
    },
}));
