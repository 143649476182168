import { Box, Button, Card, FormControl, FormHelperText, Grid, OutlinedInput, Stack, Typography } from '@mui/material';
import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Header from '../../components/header';
import Footer from '../../components/footer';
import contactusimg from '../../assets/img/contactusimg.png';
import { contactUsValidationSchema } from "../../utils/validation";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { feedback } from '../../redux/action/contactUsAction';
import "../contactus/contactus.scss";
import axios from 'axios';
import { toast } from 'react-toastify';

const initialValues = {
    fullName: '',
    primaryEmail: '',
    mobileNumber: '',
    companyName: '',
    message: '',
};

const Contactus = () => {
    const navigate = useNavigate();

   
   //Submit Form Details
    const handleSubmit = async (values, { resetForm }) => {
        console.log("values",values);
        
       try {
        const {data}= await axios.post(`${process.env.REACT_APP_BASE_URL_FRONTEND}contact/savecontact`,values);
        console.log("res77ponse",data);
         if(data.success){
           toast.success('Contact details sent successfully');
           resetForm();
           navigate('/');
         }
       } catch (error) {
       console.log(error);
      toast.error(error.message);       
       }
    };

    return (
        <Box className="paymentpage">
            <Header />
            <Box className='p-5 position-relative paddingup'>
                <Card className='contactus'>
                    <Typography variant='h4' className='fs-18 fw-600 cstm-pr-2 boderbtn'>SEND US A MESSAGE</Typography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={contactUsValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ borderBottom: "1px solid #C6C6C6" }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='cstm-pr-2'>
                                                <Stack className='d-flex flex-column gap-20 mt-4'>
                                                    <FormControl>
                                                        <Typography className='fs-14 fw-500 contactlabel'>Full Name</Typography>
                                                        <Field
                                                            as={OutlinedInput}
                                                            className='field-radious-30'
                                                            id="name"
                                                            name="fullName"
                                                            placeholder='Enter full name'
                                                        />
                                                        <ErrorMessage name="fullName" component={FormHelperText} style={{ color: 'red' }} />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='cstm-pr-2'>
                                                <Stack className='d-flex flex-column gap-20 mt-4'>
                                                    <FormControl>
                                                        <Typography className='fs-14 fw-500 contactlabel'>Primary Email</Typography>
                                                        <Field
                                                            as={OutlinedInput}
                                                            className='field-radious-30'
                                                            id="email"
                                                            name="primaryEmail"
                                                            placeholder='Enter primary email'
                                                            type="email"
                                                        />
                                                        <ErrorMessage name="primaryEmail" component={FormHelperText} style={{ color: 'red' }} />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='cstm-pr-2'>
                                                <Stack className='d-flex flex-column gap-20 mt-4'>
                                                    <FormControl>
                                                        <Typography className='fs-14 fw-500 contactlabel'>Mobile Number</Typography>
                                                        <Field
                                                            as={OutlinedInput}
                                                            className='field-radious-30'
                                                            id="phone"
                                                            name="mobileNumber"
                                                            placeholder='Enter phone number'
                                                        />
                                                        <ErrorMessage name="mobileNumber" component={FormHelperText} style={{ color: 'red' }} />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='cstm-pr-2'>
                                                <Stack className='d-flex flex-column gap-20 mt-4'>
                                                    <FormControl>
                                                        <Typography className='fs-14 fw-500 contactlabel'>Company Name</Typography>
                                                        <Field
                                                            as={OutlinedInput}
                                                            className='field-radious-30'
                                                            id="company"
                                                            name="companyName"
                                                            placeholder='Enter Company name'
                                                        />
                                                        <ErrorMessage name="companyName" component={FormHelperText} style={{ color: 'red' }} />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='cstm-pr-2'>
                                                <Stack className='d-flex flex-column gap-20 mt-4'>
                                                    <FormControl>
                                                        <Typography className='fs-14 fw-500 contactlabel'>Your Message</Typography>
                                                        <Field
                                                            as={OutlinedInput}
                                                            className='field-radious-30'
                                                            id="message"
                                                            name="message"
                                                            placeholder='Enter your description....'
                                                            multiline
                                                            rows={1}
                                                            style={{ height: '73px', borderRadius:'22px!important' }}
                                                        />
                                                        <ErrorMessage name="message" component={FormHelperText} style={{ color: 'red' }} />
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='cstm-pr-2'>
                                                <Button className='primary-btn w-100 my-4 submitbttt' type="submit" >Submit</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='imgnone' sx={{ borderBottom: "1px solid #C6C6C6" }}>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Card>
                <img className='contactusimg' src={contactusimg} />
            </Box>
            <Footer />
        </Box>
    );
}

export default Contactus;