import { Box, Grid, Typography, List, ListItem } from '@mui/material'
import React, { useEffect } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useTranslation } from 'react-i18next'
import '../../assets/styles/termsstyle.scss'
const ServiceTerms = () => {
  const { t } = useTranslation("ServiceTerm");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (

    <Box>
      <Header></Header>
      <Box className="mb-120 content_pag">
        <Grid container className='px-5'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h1' className='fs-35 fw-800 text-center mb-20 mt-54 mt-130 mb-54'>{t('termsOfService.header')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15  mt-54'><b>{t('termsOfService.effectiveDate')}</b> {t('termsOfService.effectiveDateValue')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15 mb-20'><b>{t('termsOfService.lastUpdated')}</b> {t('termsOfService.lastUpdatedValue')}</Typography>
            <Typography variant='h3' className='fs-15 fw-400 lh-15  text-center mx-auto wd-80 spacing'>{t('termsOfService.welcome')} <b>{t('termsOfService.iqHire')} </b> {t('termsOfService.welcomeMessage')}</Typography>


            {/* 1. Introduction */}

            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.introduction.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15 '>{t('termsOfService.sections.introduction.content')}</Typography>
            </Box>

            {/* 2. Services*/}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.services.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15 '>{t('termsOfService.sections.services.content')}:</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.services.list.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.services.list.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.services.list.item3')}
                  </Typography>
                </ListItem>
              </List>
            </Box>


            {/*3. User Responsibilities */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.userResponsibilities.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.userResponsibilities.content')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.userResponsibilities.list.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.userResponsibilities.list.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.userResponsibilities.list.item3')}
                  </Typography>
                </ListItem>
              </List>
            </Box>


            {/* 4. Subscription and Billing */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.subscriptionAndBilling.title')}</Typography>
              <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.subscriptionAndBilling.list.item1')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.subscriptionAndBilling.list.item2')}
                  </Typography>
                </ListItem>
                <ListItem style={{ display: 'list-item' }}>
                  <Typography variant="body1">
                    {t('termsOfService.sections.subscriptionAndBilling.list.item3')}
                  </Typography>
                </ListItem>
              </List>

            </Box>

            {/*5. Intellectual Property  */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.intellectualProperty.title')}</Typography>
              <Typography variant='h3' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.intellectualProperty.content')}</Typography>
            </Box>


            {/* 6. Data Protection*/}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.dataProtection.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.dataProtection.content')}</Typography>
            </Box>

            {/* 7. Termination */}
            <Box className="mt-20">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.termination.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.termination.content')}</Typography>
            </Box>

            {/* 8. Limitation of Liability */}
            <Box className="mt-20 ">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.limitationOfLiability.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.limitationOfLiability.content')}</Typography>
            </Box>

            {/* 9. Governing Law and Dispute Resolution*/}
            <Box className="mt-20 ">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.governingLaw.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.governingLaw.content')}</Typography>
            </Box>

            {/* 10. Changes to These Terms */}
            <Box className="mt-20 ">
              <Typography variant='h1' className='fs-22 fw-800 mb-10'>{t('termsOfService.sections.changesToTerms.title')}</Typography>
              <Typography variant='h4' className='fs-15 fw-400 lh-15'>{t('termsOfService.sections.changesToTerms.content')}</Typography>
            </Box>
            <Typography variant='h4' className='fs-15 fw-600 mb-10'>{t('termsOfService.sections.contact')}
              <b style={{ fontWeight: 600 }}>{t('termsOfService.sections.contactEmail')}</b> </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer></Footer>

    </Box>
  )
}

export default ServiceTerms
