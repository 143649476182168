import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { clientInfoValidationSchema } from '../../utils/validation';
import { trimValue } from "../../utils/utilityFunctions/trimValue";
import { clientInfo, deleteClientInfo, getProfileInfo, updateClientInfo } from '../../redux/action/profileAction';

const ClientInformation = () => {
  
  const dispatch = useDispatch();
  const userData = useSelector(state => state?.authenticationReducer);
  const clientDetails = useSelector(state => state?.profileReducer?.personalInfoDetails?.clientInfo);
  const [clients, setClients] = useState(clientDetails);
  const [isEditMode, setIsEditMode] = useState(false); // Track edit mode
  const [editClientId, setEditClientId] = useState(null); // Track the client being edited

  const initialValues = {
    name: "",
    url: "",
    description: ""
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    const trimmedValues = trimValue(values);
    setSubmitting(false);

    if (isEditMode && editClientId) {
      // Update existing client
      dispatch(updateClientInfo(editClientId, trimmedValues))
        .then(() => {
          resetForm();
          setIsEditMode(false); // Exit edit mode
          dispatch(getProfileInfo(userData?._id));
        })
        .catch((error) => {
          console.error("Error updating client information:", error);
        });
    }

    else if (userData?._id) {
      dispatch(clientInfo(userData?._id, [trimmedValues]))
        .then(() => {
          // Reset the form fields to their initial values
          resetForm();
          dispatch(getProfileInfo(userData?._id));
        })
        .catch((error) => {
          console.error("Error submitting client information:", error);
        });
    }
  };

  //Handle Client Deletion
  const handleClientDelete = (id, resetForm) => {
    dispatch(deleteClientInfo(id))
      .then(() => {
        dispatch(getProfileInfo(userData?._id));
        resetForm();
        setIsEditMode(false);
      })
      .catch((error) => {
        console.error("Error deleting client", error);
      })
  }

  //Handle Edit Functionality
  //Populate form fields with data
  const populateFormFields = (id, setFieldValue) => {
    const getClient = clients?.filter(ele => ele?._id === id);
    if (getClient) {
      setFieldValue("name", getClient[0].name || "");
      setFieldValue("url", getClient[0].url || "");
      setFieldValue("description", getClient[0].description || "");
      setEditClientId(id); // Set the client ID being edited
      setIsEditMode(true); // Enter edit mode
    }
  }

  //it's used to update the client details with the latest client information
  useEffect(() => {
    setClients(clientDetails);
  }, [clientDetails])

  return (
    <Box sx={{ boxShadow: "0px 9.63px 35.53px -24.81px #757576", padding: "32px 75px 62px 27px", borderRadius: "0px 21.75px 21.75px 21.75px" }} className="tabinfo">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="d-flex justify-content-between">
            <Typography className="color-primary fs-22 fw-600" variant="h3">
              Other Company Information
            </Typography>
          </Box>
          <Divider sx={{ marginTop: "13px", marginBottom: "5px", border: "1px solid #B5B1B1" }} />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={clientInfoValidationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, resetForm }) => (

              <Form>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                  <Button className="primary-btn-outline gap-10 fw-600 addbtnn" type="submit" disabled={isEditMode}>
                    <AddIcon />
                    Add
                  </Button>
                </Box>
                <Box sx={{ gap: "20px" }} className="flex-wrap">
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className='fs-14 fw-500' sx={{ marginBottom: "10px" }} >Company Name</Typography>
                    <Field
                      name="name"
                      as={TextField}
                      className="w-100 field-radious-30"
                      id="outlined-basic"
                      placeholder="Enter Company name"
                      variant="outlined"
                    />
                    <Box sx={{ color: 'red' }}>
                      <ErrorMessage name="name" component="div" />
                    </Box>
                  </Box>
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className='fs-14 fw-500' sx={{ marginBottom: "10px" }}>Company URL</Typography>
                    <Field
                      name="url"
                      as={TextField}
                      className="w-100 field-radious-30"
                      id="outlined-basic"
                      placeholder="Enter Company URL"
                      variant="outlined"
                    />
                    <Box sx={{ color: 'red' }}>
                      <ErrorMessage name="url" component="div" />
                    </Box>
                  </Box>
                  <Box sx={{ width: { xs: "100%", lg: "48%", xl: "31%" } }}>
                    <Typography className='fs-14 fw-500' sx={{ marginBottom: "10px" }}>Company Description</Typography>
                    <Field
                      name="description"
                      as={TextField}
                      className="w-100 field-radious-30"
                      id="outlined-basic"
                      placeholder="Enter Description"
                      variant="outlined"
                    />
                    <Box sx={{ color: 'red' }}>
                      <ErrorMessage name="description" component="div" />
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Typography className="color-primary fs-21 fw-500" variant="h3" sx={{ marginTop: "55px", marginBottom: "18px", marginLeft: "0px" }}> Client Details</Typography>
                </Box>
                <Grid item xs={12} className='tablescroll'>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ backgroundColor: '#C1C1C140', color: '#676767', fontSize: '14px', fontWeight: '600', height: '50px' }}>Company Name</TableCell>
                        <TableCell style={{ backgroundColor: '#C1C1C140', color: '#676767', fontSize: '14px', fontWeight: '600', height: '50px' }}>Company URL</TableCell>
                        <TableCell style={{ backgroundColor: '#C1C1C140', color: '#676767', fontSize: '14px', fontWeight: '600', height: '50px' }}>Description</TableCell>
                        <TableCell style={{ backgroundColor: '#C1C1C140', color: '#676767', fontSize: '14px', fontWeight: '600', height: '50px' }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clients?.map((company) => (
                        <TableRow key={company?._id}>
                          <TableCell>{company?.name}</TableCell>
                          <TableCell>{company?.url}</TableCell>
                          <TableCell>{company?.description}</TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => populateFormFields(company?._id, setFieldValue)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => handleClientDelete(company?._id, resetForm)} >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "74px" }}>
                  <Box className="flex-wrap text-end justify-content-end ml-auto w-100 gap-10">
                    <Button className="primary-btn fs-14" sx={{ width: "144px" }} type="reset">Cancel</Button>

                    <Button className="primary-btn-outline fs-14" sx={{ width: "144px" }} disabled={!isEditMode} type="submit">Update</Button>
                  </Box>
                </Grid>


              </Form>
            )}
          </Formik>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ClientInformation;

