import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import EastIcon from '@mui/icons-material/East';
import iqunderline from '../../assets/img/iqunderline.png';
import ourwork from '../../assets/img/ourwork.jfif';
import '../../pages/ourwork/ourwork.scss';
import message from "../../assets/icons/message.png";
import Vacancyimg from '../../assets/img/Vacancyimg.png';
import img1 from '../../assets/img/companylogs/img (1).png';
import img2 from '../../assets/img/companylogs/img (2).png';
import img3 from '../../assets/img/companylogs/img (3).png';
import img4 from '../../assets/img/companylogs/img (4).png';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { Link } from 'react-router-dom';
import banner02 from '../../assets/img/banner02.png';
import { useTranslation } from 'react-i18next';

const OurWork = () => {
  const { t } = useTranslation("OurWork");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <Box className='ourwrk'>
        {/* Section1*/}
        <Grid container  >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='text-start px-5 pt-5'>
            <img src={iqunderline} className='mb-4 mt-5'></img>
            <Typography variant='h3' className='fs-40 fw-700 mb-3 text-orange'>{t('section1.who_we_are')}</Typography>
            <Typography variant='h4' className='fs-17 fw-500' style={{ lineHeight: "1.5" }}>{t('section1.lorem_ipsum_dummy')}</Typography>
            <Link to='/chatbot-update' style={{ textDecoration: "none" }}>
              <Box className='d-flex gap-10 justify-content-start themebtn' sx={{ mt: "40px", mb: "00px" }} >
                <Button className='primary-btn' sx={{ px: "24px" }}>{t('section1.let_start')}</Button><Button className='primary-btn' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="hireimg">
            <img style={{ width: "100%", borderRadius: "88px 0 0 0 ", height: "100%", objectFit: "cover" }} src={ourwork} />
          </Grid>
        </Grid>
        {/*Section2*/}
        <Grid container className='px-5' style={{ marginTop: { xs: "30px", sm: "130px", md: "130" } , marginBottom:  { xs: "30px", sm: "30px" ,md: "180"}  }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className=' pt-5 pb-5'>
            <Typography variant='h4' className='fs-22 fw-500 sightitl'>{t('section2.in_sights')}</Typography>
            <Typography variant='h3' className='fs-40 fw-600 mb-3 text-orange sightitl'>{t('section2.our_work')}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card className='card flex-row'>
              <Box className="Content">
                <Box>
                  <Typography variant='h4' className='fs-28 fw-600'>{t('section2.ipsum')} </Typography>
                </Box>
                <Typography variant='h4' className='fs-14 fw-500 lin12'>{t('section2.lorem_ipsum_dummy')}</Typography>
                <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "our_work_article1" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section2.read_more')} <EastIcon></EastIcon></Link>
              </Box>
              <img className='mt-auto content-img' width={93} height={93} src={message}  />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card className='card flex-row'>
              <Box className="Content">
                <Box>
                  <Typography variant='h4' className='fs-28 fw-600'>{t('section6.ipsum')}  </Typography>
                </Box>
                <Typography variant='h4' className='fs-14 fw-500 lin12'>{t('section6.lorem_ipsum_dummy')}</Typography>
                <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "our_work_article2" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section2.read_more')} <EastIcon></EastIcon></Link>
              </Box>
              <img className='mt-auto content-img' width={93} height={93} src={message} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card className='card flex-row'>
              <Box className="Content">
                <Box>
                  <Typography variant='h4' className='fs-28 fw-600'>{t('section7.ipsum')}  </Typography>
                </Box>
                <Typography variant='h4' className='fs-14 fw-500 lin12'>{t('section7.lorem_ipsum_dummy')}</Typography>
                <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "our_work_article3" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section2.read_more')} <EastIcon></EastIcon></Link>
              </Box>
              <img className='mt-auto content-img' width={93} height={93} src={message} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card className='card flex-row'>
              <Box className="Content">
                <Box>
                  <Typography variant='h4' className='fs-28 fw-600'>{t('section8.ipsum')}  </Typography>
                </Box>
                <Typography variant='h4' className='fs-14 fw-500 lin12'>{t('section8.lorem_ipsum_dummy')}</Typography>
                <Link className='text-orange fs-18 fw-500 text-underline-none' state={{ articleNo: "our_work_article4" }} to="/readMore" style={{ maxWidth: "fit-content" }}>{t('section2.read_more')} <EastIcon></EastIcon></Link>
              </Box>
              <img className='mt-auto content-img' width={93} height={93} src={message} />
            </Card>
          </Grid>
        </Grid>
        {/*Section3 */}
        <Grid container className='px-5 revolution'>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <img style={{ width: "100%", height: "auto" }} src={banner02} />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className='d-flex'>
            <Box className="m-auto" sx={{ maxWidth: "536px" }}>
              <Typography variant='h3' className='fs-28 fw-600 mb-20 text-orange  lin14'>{t('section3.lorem_ipsum')} </Typography>
              <Typography variant='h3' className='fs-16 fw-500 mb-15 lin16'>{t('section3.lorem_ipsum_dummy')}</Typography>
            </Box>
          </Grid>
        </Grid>
        {/*Section4 */}
        <Grid container className='partnership'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='mt-148 mb-128'>
            <Typography variant='h3' className='fs-33 fw-600 mb-30 text-black text-center '>{t('section4.partnership')}</Typography>
            <Typography variant='h3' className='fs-14 fw-500 mb-50 text-black text-center mx-auto tx-clr' style={{ lineHeight: "1.5" }} sx={{ maxWidth: "555px", }}>{t('section4.lorem_ipsum_dummy')}</Typography>
            <Box className="partners">
              <img src={img4} />
              <img src={img1} />
              <img src={img2} />
              <img src={img3} />
              <img src={img4} />
              <img src={img1} />
              <img src={img2} />
              <img src={img3} />
            </Box>
          </Grid>
        </Grid>
        {/*Section5*/}

       
        <Box className="my-5 intersted">
          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
              <Card className='GenerationJobVacancy' sx={{ padding: '36px 24px 45px 55px' }}>
                <Box className='gap-20 d-flex flex-column text-start m-auto'>
                  <Typography variant='h4' className='fs-22 fw-500'>{t('section5.interested')}</Typography>
                  <Typography variant='h4' className='fs-55 fw-600'>{t('section5.generate')}</Typography>
                  <Typography variant='h4' className='fs-16 fw-600'>{t('section5.lorem_ipsum')}</Typography>
                  <Box className='d-flex gap-10 readybtn'>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtngenerat' sx={{ px: "24px" }}>{t('section5.free')}</Button>
                    </Link>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline readybtnciscl' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button>
                    </Link>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Vacancyimg} />
            </Grid>
          </Grid>
        </Box>
        {/* <Box className="mb-5">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
              <Card className='GenerationJobVacancy' sx={{ padding: '8rem 3rem 9rem 5rem !important' }}>
                <Box className='gap-30 d-flex flex-column text-start m-auto'>
                  <Box>
                    <Typography variant='h4' className='fs-22 fw-500 mb-12'>{t('section5.interested')}</Typography>
                    <Typography variant='h4' className='fs-55 fw-600 mb-12'>{t('section5.generate')}</Typography>
                    <Typography variant='h4' className='fs-16 fw-600 lh-15'>{t('section5.lorem_ipsum')} </Typography>
                  </Box>
                  <Box className='d-flex gap-10 themebtn'>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline' sx={{ px: "24px" }}>{t('section5.free')}</Button></Link>
                    <Link to={'/chatbot-update'}><Button className='primary-btn-outline' sx={{ minWidth: 'fit-content !important' }}><EastIcon></EastIcon> </Button></Link>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Vacancyimg} />
            </Grid>
          </Grid>
        </Box> */}
      </Box>
      <Footer />
    </>
  )
}

export default OurWork;
