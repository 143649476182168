import { Box, Button, Dialog, Typography } from '@mui/material'
import React from 'react'
import './popup.scss'
import start from "../assets/img/start.jfif"
import crossIcon from "../assets/img/cross.png"
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import '../assets/styles/mediaquery.scss';
import bglinimg from "../assets/img/linbg.png";
const Start = ({ visible, setVisible }) => {
    
    const userToken = localStorage.getItem('accessToken');
    const {t}=useTranslation("Startup");
    const navigate = useNavigate();
    const handleClose = () => {
        setVisible(false);
    };
    
    return (
        <Dialog onClose={handleClose} open={visible} className='startpopup'>
            <img src={crossIcon} alt="" className="close" onClick={handleClose} />

            <Box className=" startpopup" sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' } 
    }}>
                <Box className="content">
                <Typography variant='h2' className='text-black fs-40 fw-500 mb-25 '><span className='text-black fs-40 fw-300'>{t('startPopup.readyTo')}</span><span className='text-orange fw-500 fs-40'>{t('startPopup.header')}</span>  </Typography>
                    <Typography variant='h2' className='text-black fs-16 fw-500 mb-37 lh-15'>{t('startPopup.description')}<span className='text-orange fw-500'>{t('startPopup.callToAction')}</span>  </Typography>
                    <Button
                        onClick={() => {
                            handleClose();
                            userToken &&
                            navigate('/chatbot-update');
                            
                        }}
                        className="primary-btn"
                        sx={{ width: '144px' }}
                    >
                        {t('startPopup.buttonText')}
                    </Button>

                </Box>
                <Box className="img"  sx={{ 
            display: { xs: 'none', md: 'block' } 
        }}>
                    <img src={start} />
                </Box>
                <img src={bglinimg} alt='' className='bglinimg'/>
            </Box>
        </Dialog>
    )
}

export default Start
