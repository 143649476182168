export const columns = [
    { id: "vacancyNumber", label: "No.", minWidth: 100 },
    {
      id: "companyName",
      label: "Company Name",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    { id: "Job_Title", label: "Department Name", minWidth: 170 },
    {
      id: "vacancyLimit",
      label: "Vacancy Limit",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "companyDescription",
      label: "Company Description",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  
    {
      id: "createdAt",
      label: "Created Date",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  
  ];

  export const filterOptions = ["active", "draft", "inactive","all"];

  export const getStyles=(name, personName, theme)=>{
    const isSelected = personName.includes(name);
    return {
      fontWeight: isSelected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
      
      
      backgroundColor: isSelected
        ? "#F89B53"
        : theme.palette.background.paper,

      color: isSelected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
      };
    }

  